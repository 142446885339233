.not-found-page {
  display: flex;
  width: fit-content;
  height: fit-content;
  flex-direction: column;
  align-items: center;
  position: fixed; /* or absolute */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.not-found-image {
  width: 85vw;
  max-height: 60vh;
}

.nf-text {
  width: fit-content;
}
