.navbar {
  .nav-link {
    cursor: pointer;
    p {
      display: block;
    }
  }
}

.profile-name {
  font-size: 1.3em;
  text-transform: none;
  margin-right: 7px;
  color: #fff;
}
.profile-img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.nb-text-container {
  background: #076588 !important;
  border: none !important;
  margin: 0 1rem;
}

.nb-text-container:hover {
  background: #1e88af !important;
}

.nb-text {
  color: #a9afbbd1;
}
