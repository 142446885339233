@import "./assets/css/material-dashboard.min.css";

.tc {
  text-align: center;
}

.flex {
  display: flex;
}
.flex-column {
  flex-direction: column;
}

.custom-card {
  -moz-osx-font-smoothing: grayscale;
  backface-visibility: hidden;
  transform: translateZ(0);
  transition: transform 0.25s ease-out;

  &:hover,
  &:focus {
    cursor: pointer;
    transform: scale(1.05);
  }
}
